import { getToken } from 'firebase/app-check';
import {
    AddMatchDayServerResponse,
    AddNewsServerResponse,
    AddPlayerServerResponse,
    AddSeasonServerResponse,
    AddTeamServerResponse,
    DeleteMatchDayServerResponse,
    GetAllMatchDaysServerResponse,
    GetAllNewsServerResponse,
    GetAllPlayersServerResponse,
    GetAllSeasonsServerResponse,
    GetAllTeamsServerResponse,
    GetSiteDataServerResponse,
    MatchDay,
    News,
    Player,
    Season,
    SeasonPayload,
    SiteData,
    Team,
    UpdateMatchDayServerResponse,
    UpdateNewsServerResponse,
    UpdatePlayerServerResponse,
    UpdateSeasonServerResponse,
    UpdateSiteDataServerResponse,
    UpdateTeamServerResponse,
    GameStats,
    UpdatePlayerStatsForMatchDayServerResponse,
    GetPlayerByUidServerResponse,
    GetTeamByUidServerResponse,
    AuthenticatedUser,
    UpdateUserServerResponse,
    GetAllUsersServerResponse,
    GetAllVotingSessionServerResponse,
    Vote,
    PostVoteServerResponse,
    VotingSession,
    PostVotingSessionServerResponse,
    FantasySession,
    FantasyTeam,
    AddFantasyTeamServerResponse,
    AddFantasySessionServerResponse,
    GetAllFantasyTeamsServerResponse,
    GetAllFantasySessionsServerResponse,
} from '../types';
import { appCheck } from '../firebase-config';

let apiBaseUrl = '';
if (process.env.NODE_ENV === 'production') {
    apiBaseUrl = 'https://us-central1-rumbeerfootball-app.cloudfunctions.net/apiRumbeerFootballProduction';
} else {
    apiBaseUrl = 'http://127.0.0.1:5001/rumbeerfootball-app/us-central1/apiRumbeerFootballProduction';
}

async function getAppCheckToken() {
    try {
        const appCheckToken = await getToken(appCheck); // No need to pass anything here
        console.log('appCheckToken', appCheckToken);
        return appCheckToken.token;
    } catch (error) {
        console.error('Error retrieving App Check token:', error);
        throw error;
    }
}

// GET
interface GetUserServerResponse {
    success: boolean;
    user: any;
}

export const getUserByUID = async (uid: string, idToken: string): Promise<GetUserServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/users/getUserByUID/${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getPlayerByUid = async (playerId: string): Promise<GetPlayerByUidServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/players/${playerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error:', error);
        // @ts-ignore
        return { success: false, player: null };
    }
};

export const getTeamByUid = async (teamId: string): Promise<GetTeamByUidServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/teams/${teamId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error: any) {
        console.error('Error:', error);
        // @ts-ignore
        return { success: false, team: null };
    }
};

export const getAllPlayers = async (): Promise<GetAllPlayersServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/players`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getAllUsers = async (): Promise<GetAllUsersServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/users`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching all users:', error);
        throw error; // Update to rethrow the error after logging
    }
};

export const getAllTeams = async (): Promise<GetAllTeamsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/teams`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching all teams:', error);
        throw error; // Update to rethrow the error after logging
    }
};

export const getAllNews = async (): Promise<GetAllNewsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/news`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching all news:', error);
        throw error; // Update to rethrow the error after logging
    }
};

export const getAllSeasons = async (): Promise<GetAllSeasonsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/seasons`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching all seasons:', error);
        throw error; // Update to rethrow the error after logging
    }
};

export const getSiteData = async (): Promise<GetSiteDataServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/siteData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching site data:', error);
        throw error; // Update to rethrow the error after logging
    }
};

export const getMatchDays = async (): Promise<GetAllMatchDaysServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/matchDays`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching match days:', error);
        throw error;
    }
};

export const getVotingSessions = async (): Promise<GetAllVotingSessionServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/votingSessions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching voting sessions:', error);
        throw error;
    }
};

export const getAllFantasyTeams = async (): Promise<GetAllFantasyTeamsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/fantasyTeams`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching fantasy teams:', error);
        throw error;
    }
};

export const getAllFantasySessions = async (): Promise<GetAllFantasySessionsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/fantasySessions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error fetching fantasy sessions:', error);
        throw error;
    }
};

// POST Request
export const addUser = async (user: any) => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(user),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.text();
        });
    } catch (error) {
        console.error('Error adding user:', error);
        throw error;
    }
};

export const addPlayer = async (player: Player): Promise<AddPlayerServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/players`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(player),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error adding player:', error);
        throw error;
    }
};

export const addTeam = async (team: Team): Promise<AddTeamServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/teams`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(team),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error adding team:', error);
        throw error;
    }
};

export const addNews = async (news: News): Promise<AddNewsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/news`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(news),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error adding news:', error);
        throw error;
    }
};

export const addSeason = async (season: SeasonPayload): Promise<AddSeasonServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/seasons`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(season),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error adding season:', error);
        throw error;
    }
};

export const addMatchDay = async (matchDay: MatchDay): Promise<AddMatchDayServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/matchDays`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(matchDay),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error adding match day:', error);
        throw error;
    }
};

export const postVote = async (vote: Vote): Promise<PostVoteServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/votes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(vote),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error posting vote:', error);
        throw error;
    }
};

export const postVotingSession = async (vote: VotingSession): Promise<PostVotingSessionServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/votingSessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(vote),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error posting voting session:', error);
        throw error;
    }
};

export const postFantasyTeam = async (fantasyTeam: FantasyTeam): Promise<AddFantasyTeamServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/fantasyTeams`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(fantasyTeam),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error posting fantasy team:', error);
        throw error;
    }
};

export const postFantasySession = async (fantasySession: FantasySession): Promise<AddFantasySessionServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/fantasySessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(fantasySession),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error posting fantasy session:', error);
        throw error;
    }
};

// PUT Requests
export const updateUser = async (userId: string, user: AuthenticatedUser): Promise<UpdateUserServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(user),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

export const updatePlayer = async (playerId: string, player: Player): Promise<UpdatePlayerServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/players/${playerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(player),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating player:', error);
        throw error;
    }
};

export const updateTeam = async (teamId: string, team: Team): Promise<UpdateTeamServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/teams/${teamId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(team),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating team:', error);
        throw error;
    }
};

export const updateNews = async (newsId: string, news: News): Promise<UpdateNewsServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/news/${newsId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(news),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating news:', error);
        throw error;
    }
};

export const updateSeason = async (seasonId: string, season: Season): Promise<UpdateSeasonServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/seasons/${seasonId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(season),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating season:', error);
        throw error;
    }
};

export const updateSiteData = async (siteData: SiteData): Promise<UpdateSiteDataServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/siteData/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(siteData),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating site data:', error);
        throw error;
    }
};

export const updateMatchDay = async (matchDayId: string, matchDay: MatchDay): Promise<UpdateMatchDayServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/matchDays/${matchDayId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(matchDay),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating match day:', error);
        throw error;
    }
};

export const updateFantasyTeamWeek = async (
    fantasyTeamUid: string,
    fantasySessionUid: string,
    players: string[]
): Promise<UpdateMatchDayServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/fantasyTeams/${fantasyTeamUid}/updateFantasyTeamWeek`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify({ fantasySessionUid, players }),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating fantasy team week:', error);
        throw error;
    }
};

export const updatePlayerStatsForMatch = async (
    playerId: string,
    matchDayId: string,
    seasonUid: string,
    stats: GameStats
): Promise<UpdatePlayerStatsForMatchDayServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/players/${playerId}/${seasonUid}/${matchDayId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
            body: JSON.stringify(stats),
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error updating player stats:', error);
        throw error;
    }
};

// DELETE Request
export const deleteMatchDay = async (matchId: string): Promise<DeleteMatchDayServerResponse> => {
    try {
        const appCheckToken = await getAppCheckToken();
        return fetch(`${apiBaseUrl}/matchDays/${matchId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-Firebase-AppCheck': appCheckToken, // Add App Check token
            },
        }).then((res) => {
            if (!res.ok) {
                throw new Error(`Server responded with status code ${res.status}`);
            }
            return res.json();
        });
    } catch (error) {
        console.error('Error deleting match day:', error);
        throw error;
    }
};
