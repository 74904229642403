import {
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from '../store/store';
import { FantasyTeam } from '../types';
import { calculatePointsWithinTimeframe } from '../utils/helpers';

export const FantasyMiniLeaderboard = (): JSX.Element => {
    const users = useAppSelector((state) => state.user.users);
    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const fantasyTeams = useAppSelector((state) => state.fantasyTeam.fantasyTeams);
    const fantasySessions = useAppSelector((state) => state.fantasySession.fantasySessions);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    const [loading, setLoading] = React.useState<boolean>(true);
    const [selectedWeek, setSelectedWeek] = useState<number>(0); // Default to "All Weeks"
    const [filteredFantasyTeams, setFilteredFantasyTeams] = React.useState<FantasyTeam[]>([]);
    const [rows, setRows] = React.useState<any[]>([]);
    const [weeks, setWeeks] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (users && players && matchDays && fantasyTeams && fantasySessions && siteData) {
            setLoading(false);
        } else setLoading(true);
    }, [users, players, matchDays, fantasyTeams, fantasySessions, siteData]);

    React.useEffect(() => {
        if (fantasySessions && siteData) {
            const currentSeasonSessions = fantasySessions.filter((session) => session.seasonUid === siteData.seasonUid);
            const weekNumbers = currentSeasonSessions.map((session) => session.weekNumber).sort((a, b) => a - b);
            setWeeks([0, ...weekNumbers]); // Add "All Weeks" option
            if (weekNumbers.length > 0) {
                setSelectedWeek(0); // Default to "All Weeks"
            }
        }
    }, [fantasySessions, siteData]);

    React.useEffect(() => {
        if (fantasyTeams) {
            const tempFilteredFantasyTeams: FantasyTeam[] = fantasyTeams.filter((team) =>
                selectedWeek === 0
                    ? true // Include all weeks
                    : team.weeks.some((week) => week.weekNumber === selectedWeek)
            );
            setFilteredFantasyTeams(tempFilteredFantasyTeams);
        }
    }, [fantasyTeams, selectedWeek]);

    React.useEffect(() => {
        if (filteredFantasyTeams && players && fantasySessions && siteData && matchDays && users) {
            const tempRows = filteredFantasyTeams
                .map((team) => {
                    const relevantWeeks =
                        selectedWeek === 0
                            ? team.weeks // Include all weeks
                            : team.weeks.filter((w) => w.weekNumber === selectedWeek);

                    const points = relevantWeeks.reduce((weekPoints, week) => {
                        const session = fantasySessions.find((fs) => fs.uid === week.fantasySessionUid);

                        const weekTotal = week.players.reduce((totalPoints, playerUid) => {
                            const player = players.find((p) => p.uid === playerUid);

                            if (player && session) {
                                return (
                                    totalPoints +
                                    calculatePointsWithinTimeframe(
                                        session.startDate,
                                        session.endDate,
                                        siteData.seasonUid,
                                        matchDays,
                                        player
                                    )
                                );
                            }
                            return totalPoints;
                        }, 0);

                        return weekPoints + weekTotal;
                    }, 0);

                    const ownerUserName: string | undefined = users.find((u) => u.uid === team.ownerUid)?.email;
                    const playerUserName: string | undefined = players.find(
                        (p) => p.uid === users.find((u) => u.uid === team.ownerUid)?.playerUid
                    )?.name;

                    const ownerName: string | undefined = playerUserName || ownerUserName;

                    return {
                        teamName: team.teamName,
                        ownerName: ownerName || 'Unknown',
                        points,
                    };
                })
                .sort((a, b) => b.points - a.points);
            setRows(tempRows);
        }
    }, [filteredFantasyTeams, players, fantasySessions, siteData, matchDays, selectedWeek, users]);

    const handleWeekChange = (event: SelectChangeEvent<number>) => {
        setSelectedWeek(event.target.value as number);
    };

    return loading ? (
        <Skeleton />
    ) : (
        <Card variant='outlined'>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl>
                            <InputLabel id='week-select-label'>Week</InputLabel>
                            <Select
                                labelId='week-select-label'
                                value={selectedWeek}
                                onChange={handleWeekChange}
                                label='Week'
                                size='small'
                            >
                                <MenuItem value={0}>All Weeks</MenuItem>
                                {weeks.map(
                                    (weekNumber) =>
                                        weekNumber !== 0 && ( // Skip "All Weeks" for duplicate rendering
                                            <MenuItem key={weekNumber} value={weekNumber}>
                                                Week {weekNumber}
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>Position</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Team Name</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Owner</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Points</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index} style={{ background: index % 2 === 0 ? '#f9f9f9' : '' }}>
                                            <TableCell>{index + 1}.</TableCell>
                                            <TableCell>{row.teamName}</TableCell>
                                            <TableCell>{row.ownerName}</TableCell>
                                            <TableCell>{row.points.toFixed(1)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
