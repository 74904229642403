import React from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { TeamsBar } from './TeamsBar';
// import twitchLogo from '../assets/twitch.png';

const navStyle: React.CSSProperties = {
    color: 'black',
};

const buttonStyle: React.CSSProperties = {
    fontWeight: 'bold',
};

// const getToken = async (clientId: string, clientSecret: string): Promise<string> => {
//     const response = await fetch('https://id.twitch.tv/oauth2/token', {
//         method: 'POST',
//         body: new URLSearchParams({
//             client_id: clientId,
//             client_secret: clientSecret,
//             grant_type: 'client_credentials',
//         }),
//     });
//     const data = await response.json();
//     return data.access_token;
// };

// interface StreamResponse {
//     data: Array<{ id: string }>;
// }

// const checkLiveStream = async (token: string, userId: string, clientId: string): Promise<boolean> => {
//     const response = await fetch(`https://api.twitch.tv/helix/streams?user_id=${userId}`, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//             'Client-Id': clientId,
//         },
//     });
//     const data: StreamResponse = await response.json();
//     return data.data.length > 0;
// };

export const PublicNavbar: React.FC = (): JSX.Element => {
    // const [isLive, setIsLive] = React.useState<boolean>(false);
    // const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID || '';
    // const clientSecret = process.env.REACT_APP_TWITCH_CLIENT_SECRET || '';
    // const userId = process.env.REACT_APP_TWITCH_USER_ID || '';
    // const userLogin = process.env.REACT_APP_TWITCH_USER_LOGIN || '';

    // React.useEffect(() => {
    //     const fetchLiveStatus = async () => {
    //         try {
    //             const token = await getToken(clientId, clientSecret);
    //             const live = await checkLiveStream(token, userId, clientId);
    //             // setIsLive(live);
    //         } catch (error) {
    //             console.error('Error fetching live status:', error);
    //         }
    //     };

    //     fetchLiveStatus();
    // }, [clientId, clientSecret, userId]);

    return (
        <div
            style={{
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'fixed',
                width: '100%',
                zIndex: 1000,
            }}
        >
            <TeamsBar />
            <div
                style={{
                    background: 'white',
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: 12,
                    // borderBottom: '2px solid #eee',
                    // borderTop: '2px solid #eee',
                }}
            >
                <NavLink style={navStyle} to='/'>
                    <Button style={buttonStyle} color='inherit'>
                        Home
                    </Button>
                </NavLink>
                <NavLink style={navStyle} to='/players'>
                    <Button style={buttonStyle} color='inherit'>
                        Players
                    </Button>
                </NavLink>
                <NavLink style={navStyle} to='/teams'>
                    <Button style={buttonStyle} color='inherit'>
                        Teams
                    </Button>
                </NavLink>
                <NavLink style={navStyle} to='/fantasy-teams'>
                    <Button style={buttonStyle} color='inherit'>
                        Fantasy
                    </Button>
                </NavLink>
                <NavLink style={navStyle} to='/matches'>
                    <Button style={buttonStyle} color='inherit'>
                        Matches
                    </Button>
                </NavLink>
                {/* <NavLink
                    style={{ textDecoration: 'none', position: 'relative' }}
                    to={`https://www.twitch.tv/${userLogin}`}
                    target='_blank'
                >
                    <Button
                        style={{
                            ...buttonStyle,
                            background: '#18181b',
                            color: 'white',
                            outline: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        color='inherit'
                        variant='outlined'
                    >
                        <img src={twitchLogo} alt='Twitch Logo' width='20' style={{ marginRight: '8px' }} />
                        Stream
                        {isLive && <div className='pulse-circle'></div>}
                    </Button>
                </NavLink> */}
            </div>
        </div>
    );
};
