import React from 'react';
import {
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { useAppSelector } from '../../../store/store';
import { calculatePointsWithinTimeframe } from '../../../utils/helpers';

export const FantasyTeams = (): JSX.Element => {
    const [loading, setLoading] = React.useState(true);
    const [selectedWeek, setSelectedWeek] = React.useState<number | undefined>(undefined);

    const fantasyTeams = useAppSelector((state) => state.fantasyTeam.fantasyTeams);
    const fantasySessions = useAppSelector((state) => state.fantasySession.fantasySessions);
    const players = useAppSelector((state) => state.player.players);
    const matchDays = useAppSelector((state) => state.matchDay.matchDays);
    const siteData = useAppSelector((state) => state.siteData.siteData);
    const users = useAppSelector((state) => state.user.users);

    React.useEffect(() => {
        if (fantasyTeams && fantasySessions) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [fantasyTeams, fantasySessions]);

    const handleWeekChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedWeek(event.target.value as number);
    };

    const sortedFantasySessions = React.useMemo(() => {
        return fantasySessions?.slice().sort((a, b) => a.weekNumber - b.weekNumber);
    }, [fantasySessions]);

    const filteredTeams = React.useMemo(() => {
        if (!fantasyTeams || !fantasySessions) return [];

        return fantasyTeams.filter((team) =>
            team.weeks.some((week) => {
                if (selectedWeek) {
                    return week.weekNumber === selectedWeek; // Match specific week
                }
                return true; // Match all weeks
            })
        );
    }, [fantasyTeams, fantasySessions, selectedWeek]);

    React.useEffect(() => {
        console.log('Filtered Teams:', filteredTeams);
    }, [filteredTeams]);

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading fantasy teams...' />
            ) : (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Fantasy Teams</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={selectedWeek || ''}
                                onChange={(e: any) => handleWeekChange(e)}
                                displayEmpty
                                fullWidth
                                size='small'
                            >
                                <MenuItem value=''>
                                    <em>All Weeks</em>
                                </MenuItem>
                                {sortedFantasySessions?.map((session) => (
                                    <MenuItem key={session.weekNumber} value={session.weekNumber}>
                                        Week {session.weekNumber}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant='outlined'>
                                <CardContent>
                                    <Typography variant='h5'>Leaderboard</Typography>
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <b>Position</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>Owner</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>Team Name</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>Total Points</b>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredTeams
                                                    .map((team) => {
                                                        const totalPoints = team.weeks.reduce((acc, week) => {
                                                            if (selectedWeek && week.weekNumber !== selectedWeek)
                                                                return acc;

                                                            const session = fantasySessions?.find(
                                                                (fs) => fs.uid === week.fantasySessionUid
                                                            );

                                                            const weekPoints = week.players.reduce(
                                                                (playerAcc, playerUid) => {
                                                                    const player = players?.find(
                                                                        (p) => p.uid === playerUid
                                                                    );
                                                                    if (player && session) {
                                                                        return (
                                                                            playerAcc +
                                                                            calculatePointsWithinTimeframe(
                                                                                session.startDate || '',
                                                                                session.endDate || '',
                                                                                siteData?.seasonUid || '',
                                                                                matchDays || [],
                                                                                player
                                                                            )
                                                                        );
                                                                    }
                                                                    return playerAcc;
                                                                },
                                                                0
                                                            );

                                                            return acc + weekPoints;
                                                        }, 0);

                                                        const ownerEmail =
                                                            users?.find((user) => user.uid === team.ownerUid)?.email ||
                                                            'Unknown';

                                                        return {
                                                            teamUid: team.uid,
                                                            owner: ownerEmail,
                                                            teamName: team.teamName,
                                                            totalPoints: Math.round(totalPoints * 100) / 100,
                                                        };
                                                    })
                                                    .sort((a, b) => b.totalPoints - a.totalPoints) // Sort by points in descending order
                                                    .map((team, index) => (
                                                        <TableRow
                                                            key={team.teamUid}
                                                            style={{
                                                                background: index % 2 === 0 ? '#f9f9f9' : '',
                                                            }}
                                                        >
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{team.owner}</TableCell>
                                                            <TableCell>{team.teamName}</TableCell>
                                                            <TableCell>{team.totalPoints}</TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid container spacing={2} item xs={12}>
                            {filteredTeams.map((team) =>
                                team.weeks
                                    .filter((week) => !selectedWeek || week.weekNumber === selectedWeek)
                                    .map((week) => {
                                        const totalPoints = week.players.reduce((acc, playerUid) => {
                                            const player = players?.find((p) => p.uid === playerUid);
                                            const session = sortedFantasySessions?.find(
                                                (fs) => fs.uid === week.fantasySessionUid
                                            );

                                            if (player && session) {
                                                const points = calculatePointsWithinTimeframe(
                                                    session.startDate || '',
                                                    session.endDate || '',
                                                    siteData?.seasonUid || '',
                                                    matchDays || [],
                                                    player
                                                );
                                                return acc + points;
                                            }
                                            return acc;
                                        }, 0);

                                        const ownerEmail =
                                            users?.find((user) => user.uid === team.ownerUid)?.email || 'Unknown';

                                        return (
                                            <Grid
                                                key={`${team.uid}-${week.weekNumber}`}
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant='h6'>
                                                            <b>{team.teamName}</b>
                                                        </Typography>

                                                        <Typography variant='caption'>Owner: {ownerEmail}</Typography>
                                                        <Divider light />
                                                        <Typography variant='body1' mt={1}>
                                                            <b>Week {week.weekNumber}</b>
                                                        </Typography>
                                                        {week.players.map((playerUid, index) => {
                                                            const player = players?.find((p) => p.uid === playerUid);
                                                            let playerPoints = 0;

                                                            if (player) {
                                                                const session = sortedFantasySessions?.find(
                                                                    (fs) => fs.uid === week.fantasySessionUid
                                                                );
                                                                playerPoints = session
                                                                    ? calculatePointsWithinTimeframe(
                                                                          session.startDate || '',
                                                                          session.endDate || '',
                                                                          siteData?.seasonUid || '',
                                                                          matchDays || [],
                                                                          player
                                                                      )
                                                                    : 0;
                                                            }

                                                            return (
                                                                <Typography key={playerUid} variant='body2'>
                                                                    {index + 1}. {player?.name} -{' '}
                                                                    <b>{Math.round(playerPoints * 100) / 100} pts</b>
                                                                </Typography>
                                                            );
                                                        })}
                                                        <Divider light sx={{ marginY: 1 }} />
                                                        <Typography variant='body2'>
                                                            <b>Total Points:</b> {Math.round(totalPoints * 100) / 100}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        );
                                    })
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Container>
    );
};
