import React from 'react';
import { CustomAlertProps, FantasySession, AddFantasySessionServerResponse } from '../../../types';
import {
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { initialAlertState, initialnewFantasySessionState } from '../../../utils/consts';
import { LoadingOverlay } from '../../../components/LoadingOverlay';
import { CustomAlert } from '../../../components/CustomAlert';
import { CustomMaterialTable } from '../../../components/CustomMaterialTable';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { formatDate } from '../../../utils/helpers';
import { postFantasySession as postFantasySessionAsync } from '../../../utils/actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { Box } from '@mui/system';
import { modalStyle } from '../../../styles';
import AddIcon from '@mui/icons-material/Add';

export const FantasyManager = () => {
    const dispatch = useAppDispatch();
    const fantasySessions = useAppSelector((state) => state.fantasySession.fantasySessions);
    const siteData = useAppSelector((state) => state.siteData.siteData);

    const [formattedFantasySessions, setFormattedFantasySessions] = React.useState<FantasySession[]>([]);
    const [customAlertState, setCustomAlertState] = React.useState<CustomAlertProps>({
        ...initialAlertState,
        closeAlert: () => handleCloseAlert(),
    });
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addButtonIsProcessing, setAddButtonIsProcessing] = React.useState<boolean>(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState<boolean>(true);
    const [newFantasySession, setNewFantasySession] = React.useState<FantasySession>(initialnewFantasySessionState);

    React.useEffect(() => {
        if (newFantasySession.startDate && newFantasySession.endDate) {
            setSubmitButtonDisabled(false);
        } else setSubmitButtonDisabled(true);
    }, [newFantasySession]);

    React.useEffect(() => {
        if (fantasySessions && siteData) {
            setLoading(false);
            const tempFormattedFantasySessions: FantasySession[] = fantasySessions
                .filter((fs) => fs.seasonUid === siteData.seasonUid)
                .map((fs) => {
                    return {
                        ...fs,
                        startDate: formatDate(fs.startDate),
                        endDate: formatDate(fs.endDate),
                    };
                });
            setFormattedFantasySessions([...tempFormattedFantasySessions]);
        } else setLoading(true);
    }, [fantasySessions, siteData]);

    const handleCloseAlert = () => {
        setCustomAlertState((prev) => {
            prev = { ...prev };
            prev.open = false;
            return prev;
        });
    };

    const handleModalClose = (): void => {
        setNewFantasySession(initialnewFantasySessionState);
        setIsModalOpen(false);
    };

    const handleAddFantasySession = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        try {
            setAddButtonIsProcessing(true);
            const completeFantasySessionData: FantasySession = {
                ...newFantasySession,
                seasonUid: siteData?.seasonUid || '',
            };
            const action = await dispatch(postFantasySessionAsync(completeFantasySessionData));
            const data: AddFantasySessionServerResponse = action.payload as AddFantasySessionServerResponse;
            if (data.success) {
                setNewFantasySession(initialnewFantasySessionState);
                setCustomAlertState((prev) => {
                    prev = { ...prev };
                    prev.open = true;
                    prev.message = data.message;
                    prev.severity = 'success';
                    return prev;
                });
                setIsModalOpen(false);
            }
        } catch (error: any) {
            console.error('Error adding fantasy session:', error.message);
            setCustomAlertState((prev) => {
                prev = { ...prev };
                prev.open = true;
                prev.message = error.message;
                prev.severity = 'error';
                return prev;
            });
        } finally {
            setAddButtonIsProcessing(false);
        }
    };

    const CreateFantasySessionComponent = (): JSX.Element => {
        return (
            <Grid item xs={12}>
                <Typography variant='h6'>Create Fantasy Session</Typography>
                <Card>
                    <CardContent>
                        <form onSubmit={handleAddFantasySession}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            label='Status'
                                            value={newFantasySession.status}
                                            onChange={(e) => {
                                                setNewFantasySession({
                                                    ...newFantasySession,
                                                    status: e.target.value as 'open' | 'closed',
                                                });
                                            }}
                                        >
                                            <MenuItem value='open'>Open</MenuItem>
                                            <MenuItem value='closed'>Closed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Team Creation Open</InputLabel>
                                        <Select
                                            fullWidth
                                            size='small'
                                            label='Status'
                                            value={newFantasySession.teamCreationStatus}
                                            onChange={(e) => {
                                                setNewFantasySession({
                                                    ...newFantasySession,
                                                    teamCreationStatus: e.target.value as 'open' | 'closed',
                                                });
                                            }}
                                        >
                                            <MenuItem value='open'>Open</MenuItem>
                                            <MenuItem value='closed'>Closed</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>Start Date</Typography>
                                    <TextField
                                        type='datetime-local'
                                        value={newFantasySession.startDate}
                                        onChange={(e) => {
                                            setNewFantasySession({ ...newFantasySession, startDate: e.target.value });
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle2'>End Date</Typography>
                                    <TextField
                                        type='datetime-local'
                                        value={newFantasySession.endDate}
                                        onChange={(e) => {
                                            setNewFantasySession({ ...newFantasySession, endDate: e.target.value });
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        disabled={submitButtonDisabled}
                                        isProcessing={addButtonIsProcessing}
                                        type='submit'
                                        text='Add Fantasy Session'
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <Container maxWidth='xl'>
            {loading ? (
                <LoadingOverlay message='Loading vote sessions.' />
            ) : siteData?.inSeason ? (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Fantasy Manager</Typography>
                            <CustomMaterialTable
                                data={formattedFantasySessions || []}
                                ignoredKeys={[]}
                                keysOrder={['weekNumber', 'startDate', 'endDate', 'status', 'teamCreationOpen', 'uid']}
                                exportDisabled
                                path='/admin-dashboard/fantasy-manager'
                                enableRowSelection={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Create Fantasy Session
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal open={isModalOpen} onClose={handleModalClose}>
                        <Box sx={modalStyle}>{CreateFantasySessionComponent()}</Box>
                    </Modal>
                    <CustomAlert {...customAlertState} />
                </>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Fantasy Manager</Typography>
                        <Typography variant='caption'>Not currently in season.</Typography>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};
